import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Divider,
  Card,
  CardContent,
  Slider,
  Rating,
  Tooltip,
  Alert,
  InputAdornment,
} from '@mui/material';
import { motion } from 'framer-motion';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';

const MotionCard = motion(Card);

// Investment options in Kenya with their typical returns and risk levels
const INVESTMENT_OPTIONS = {
  'Treasury Bills': {
    minReturn: 7,
    maxReturn: 9,
    riskScore: 1,
    description: 'Government-backed short-term securities. Very low risk, stable returns.',
  },
  'Treasury Bonds': {
    minReturn: 11,
    maxReturn: 13,
    riskScore: 2,
    description: 'Government-backed long-term securities. Low risk, higher returns than T-bills.',
  },
  'Money Market Fund': {
    minReturn: 8,
    maxReturn: 10,
    riskScore: 2,
    description: 'Pooled fund investing in short-term securities. Low risk, good liquidity.',
  },
  'Corporate Bonds': {
    minReturn: 12,
    maxReturn: 15,
    riskScore: 3,
    description: 'Company-issued debt securities. Medium risk, higher returns than government bonds.',
  },
  'SACCO Shares': {
    minReturn: 10,
    maxReturn: 14,
    riskScore: 3,
    description: 'Cooperative society shares. Medium risk, consistent returns.',
  },
  'Real Estate Investment': {
    minReturn: 8,
    maxReturn: 20,
    riskScore: 4,
    description: 'Property investments. Higher risk, potential for significant appreciation.',
  },
  'NSE Stocks': {
    minReturn: 10,
    maxReturn: 25,
    riskScore: 5,
    description: 'Stock market investments. High risk, potential for high returns.',
  },
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#FFA07A'];

const formatCurrency = (value) => {
  if (!value && value !== 0) return '';
  return `KES ${Number(value).toLocaleString('en-KE')}`;
};

const parseAmount = (value) => {
  if (!value) return 0;
  return Number(value) || 0;
};

const FinancialCalculators = () => {
  // Net Worth Calculator State
  const [assets, setAssets] = useState({
    cash: '',
    investments: '',
    realEstate: '',
    vehicles: '',
    otherAssets: '',
  });

  const [liabilities, setLiabilities] = useState({
    loans: '',
    mortgages: '',
    creditCards: '',
    otherDebts: '',
  });

  // Investment Calculator State
  const [investment, setInvestment] = useState({
    amount: '',
    period: 12,
    selectedOption: 'Treasury Bills',
  });

  // Derived state for pie chart
  const [pieData, setPieData] = useState([]);
  const [netWorthPieData, setNetWorthPieData] = useState([]);

  // Update pie data when assets or liabilities change
  useEffect(() => {
    const assetsTotal = getPieChartData();
    setNetWorthPieData(assetsTotal);
  }, [assets, liabilities]);

  const calculateNetWorth = () => {
    const totalAssets = Object.values(assets).reduce((sum, value) => {
      return sum + parseAmount(value);
    }, 0);

    const totalLiabilities = Object.values(liabilities).reduce((sum, value) => {
      return sum + parseAmount(value);
    }, 0);

    return totalAssets - totalLiabilities;
  };

  const getPieChartData = () => {
    const assetsTotal = Object.entries(assets).reduce((acc, [key, value]) => {
      const numValue = parseAmount(value);
      if (numValue > 0) {
        acc.push({
          name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
          value: numValue
        });
      }
      return acc;
    }, []);

    const liabilitiesTotal = Object.entries(liabilities).reduce((acc, [key, value]) => {
      const numValue = parseAmount(value);
      if (numValue > 0) {
        acc.push({
          name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'),
          value: -numValue
        });
      }
      return acc;
    }, []);

    return [...assetsTotal, ...liabilitiesTotal];
  };

  const handleAssetChange = (field) => (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setAssets(prev => ({ ...prev, [field]: value }));
  };

  const handleLiabilityChange = (field) => (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '');
    setLiabilities(prev => ({ ...prev, [field]: value }));
  };

  const calculateInvestmentReturns = () => {
    const option = INVESTMENT_OPTIONS[investment.selectedOption];
    const amount = Number(investment.amount);
    const months = Number(investment.period);
    
    // Calculate monthly returns based on annual rate
    const minMonthlyRate = option.minReturn / 100 / 12;
    const maxMonthlyRate = option.maxReturn / 100 / 12;

    // Calculate future value using compound interest formula
    // FV = PV * (1 + r)^t where:
    // PV = Present Value (initial investment)
    // r = interest rate (monthly)
    // t = time (in months)
    const minFutureValue = amount * Math.pow(1 + minMonthlyRate, months);
    const maxFutureValue = amount * Math.pow(1 + maxMonthlyRate, months);

    // Calculate absolute returns
    const minTotalReturn = minFutureValue - amount;
    const maxTotalReturn = maxFutureValue - amount;

    // Calculate average monthly returns over the period
    const minMonthlyReturn = minTotalReturn / months;
    const maxMonthlyReturn = maxTotalReturn / months;

    // Calculate yearly returns (annualized)
    const minYearlyReturn = minMonthlyReturn * 12;
    const maxYearlyReturn = maxMonthlyReturn * 12;
    
    return {
      monthly: {
        min: minMonthlyReturn,
        max: maxMonthlyReturn,
      },
      yearly: {
        min: minYearlyReturn,
        max: maxYearlyReturn,
      }
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={4}>
        {/* Net Worth Calculator */}
        <Grid item xs={12} md={6}>
          <MotionCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                Net Worth Calculator
              </Typography>
              
              <Typography variant="h6" gutterBottom>
                Assets
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(assets).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      fullWidth
                      label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      type="number"
                      value={value}
                      onChange={handleAssetChange(key)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">KES</InputAdornment>,
                      }}
                      placeholder="0"
                    />
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 3 }} />

              <Typography variant="h6" gutterBottom>
                Liabilities
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(liabilities).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      fullWidth
                      label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                      type="number"
                      value={value}
                      onChange={handleLiabilityChange(key)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">KES</InputAdornment>,
                      }}
                      placeholder="0"
                    />
                  </Grid>
                ))}
              </Grid>

              <Box sx={{ mt: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Net Worth: {calculateNetWorth() !== 0 ? formatCurrency(calculateNetWorth()) : ''}
                </Typography>
                
                {calculateNetWorth() !== 0 && (
                  <Box sx={{ width: '100%', height: 300, mt: 2 }}>
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={netWorthPieData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          label
                        >
                          {netWorthPieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Legend />
                        <RechartsTooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                )}
              </Box>
            </CardContent>
          </MotionCard>
        </Grid>

        {/* Investment Calculator */}
        <Grid item xs={12} md={6}>
          <MotionCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                Investment Returns Calculator
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Investment Amount"
                    type="number"
                    value={investment.amount}
                    onChange={(e) => setInvestment({ ...investment, amount: e.target.value === '' ? '' : Number(e.target.value) })}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">KES</InputAdornment>,
                    }}
                    placeholder="0"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Typography gutterBottom>Investment Period (Months)</Typography>
                  <Slider
                    value={investment.period}
                    onChange={(e, newValue) => setInvestment({ ...investment, period: newValue })}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={60}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom>Investment Type</Typography>
                  {Object.entries(INVESTMENT_OPTIONS).map(([name, details]) => (
                    <Box
                      key={name}
                      onClick={() => setInvestment({ ...investment, selectedOption: name })}
                      sx={{
                        p: 2,
                        mb: 1,
                        border: '1px solid',
                        borderColor: investment.selectedOption === name ? 'primary.main' : 'grey.300',
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          borderColor: 'primary.main',
                        },
                      }}
                    >
                      <Typography variant="subtitle1">{name}</Typography>
                      <Rating
                        value={details.riskScore}
                        readOnly
                        max={5}
                        size="small"
                        sx={{ mb: 1 }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {details.description}
                      </Typography>
                      <Typography variant="body2" color="primary">
                        Expected Return: {details.minReturn}% - {details.maxReturn}% per year
                      </Typography>
                    </Box>
                  ))}
                </Grid>

                {investment.amount > 0 && (
                  <Grid item xs={12}>
                    <Box sx={{
                      background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.6) 100%)',
                      backdropFilter: 'blur(8px)',
                      borderRadius: 2,
                      boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                      p: 3,
                      border: '1px solid rgba(255,255,255,0.3)'
                    }}>
                      <Typography variant="h6" gutterBottom sx={{ 
                        color: 'primary.main',
                        fontWeight: 600,
                        mb: 2
                      }}>
                        Projected Returns • {investment.selectedOption}
                      </Typography>

                      <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} md={6}>
                          <Box sx={{
                            p: 2.5,
                            bgcolor: 'success.light',
                            borderRadius: 2,
                            color: 'white',
                            textAlign: 'center',
                            height: '100%'
                          }}>
                            <Typography variant="overline" sx={{ opacity: 0.9, fontSize: '0.85rem' }}>
                              Average Monthly Returns
                            </Typography>
                            <Typography variant="h5" sx={{ mt: 1.5, fontWeight: 600 }}>
                              {formatCurrency(calculateInvestmentReturns().monthly.min)}
                            </Typography>
                            <Typography variant="body2" sx={{ opacity: 0.9, my: 0.5 }}>
                              to
                            </Typography>
                            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                              {formatCurrency(calculateInvestmentReturns().monthly.max)}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box sx={{
                            p: 2.5,
                            bgcolor: 'primary.main',
                            borderRadius: 2,
                            color: 'white',
                            textAlign: 'center',
                            height: '100%'
                          }}>
                            <Typography variant="overline" sx={{ opacity: 0.9, fontSize: '0.85rem' }}>
                              Annualized Returns
                            </Typography>
                            <Typography variant="h5" sx={{ mt: 1.5, fontWeight: 600 }}>
                              {formatCurrency(calculateInvestmentReturns().yearly.min)}
                            </Typography>
                            <Typography variant="body2" sx={{ opacity: 0.9, my: 0.5 }}>
                              to
                            </Typography>
                            <Typography variant="h5" sx={{ fontWeight: 600 }}>
                              {formatCurrency(calculateInvestmentReturns().yearly.max)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <Typography variant="caption" sx={{ 
                        display: 'block',
                        textAlign: 'center',
                        mt: 2,
                        color: 'text.secondary'
                      }}>
                        * Projected returns over {investment.period} months using compound interest
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </MotionCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialCalculators;
