import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Paper,
} from '@mui/material';
import {
  AccountCircle,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import WealthManagementGrid from './WealthManagementGrid';

export default function Dashboard() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: '100vh', bgcolor: 'background.default' }}>
      <AppBar 
        position="fixed" 
        elevation={1}
        sx={{
          background: 'linear-gradient(135deg, #6B46C1 0%, #9F7AEA 100%)',
        }}
      >
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 700,
                letterSpacing: '0.02em',
                color: 'white',
              }}
            >
              Hassed
            </Typography>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                ml: 2,
                color: 'rgba(255, 255, 255, 0.9)',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              Wealth Management
            </Typography>
          </Box>
          
          {/* User Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="body2" 
              sx={{ 
                mr: 2, 
                display: { xs: 'none', sm: 'block' },
                color: 'rgba(255, 255, 255, 0.9)'
              }}
            >
              {currentUser?.email}
            </Typography>
            <IconButton
              size="large"
              aria-label="account menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              sx={{ color: 'white' }}
            >
              <Avatar sx={{ 
                width: 32, 
                height: 32, 
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.3)'
                }
              }}>
                <AccountCircle />
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  mt: 1.5,
                  minWidth: 180,
                  borderRadius: 2,
                  bgcolor: '#6B46C1',
                  '& .MuiMenuItem-root': {
                    px: 2,
                    py: 1,
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)'
                    }
                  },
                }
              }}
            >
              <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{ mr: 1.5, fontSize: 20, color: 'white' }} />
                <Typography variant="body2" sx={{ color: 'white' }}>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Hassed
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Your personal wealth management education platform
          </Typography>
        </Box>

        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Wealth Management Steps
          </Typography>
          <Typography variant="body1" paragraph>
            Explore these seven essential steps to build and protect your wealth in Kenya. Click on each card to learn more about the process, requirements, and associated costs.
          </Typography>
          <WealthManagementGrid />
        </Paper>
      </Container>
    </Box>
  );
}
