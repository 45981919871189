import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  ExpandMore as ExpandMoreIcon,
  PieChart as ChartIcon,
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const assetCategories = {
  property: {
    title: 'Property',
    color: '#4CAF50',
    fields: [
      { name: 'lands', label: 'Lands Value' },
      { name: 'houses', label: 'Houses Value' },
      { name: 'cars', label: 'Vehicles Value' },
    ]
  },
  commodities: {
    title: 'Commodities',
    color: '#2196F3',
    fields: [
      { name: 'jewelry', label: 'Jewelry Value' },
      { name: 'preciousMetals', label: 'Precious Metals Value' },
      { name: 'preciousStones', label: 'Precious Stones Value' },
    ]
  },
  cash: {
    title: 'Cash',
    color: '#FFC107',
    fields: [
      { name: 'bankAccounts', label: 'Bank Accounts Balance' },
      { name: 'hardCash', label: 'Physical Cash' },
    ]
  },
  bonds: {
    title: 'Bonds/Shares',
    color: '#9C27B0',
    fields: [
      { name: 'governmentBonds', label: 'Government Bonds Value' },
      { name: 'corporateShares', label: 'Corporate Shares Value' },
      { name: 'saccoShares', label: 'SACCO Shares Value' },
    ]
  },
  equities: {
    title: 'Equities',
    color: '#FF5722',
    fields: [
      { name: 'companyEquity', label: 'Company Equity Value' },
      { name: 'startupShares', label: 'Startup Shares Value' },
    ]
  },
  debt: {
    title: 'Debt',
    color: '#F44336',
    fields: [
      { name: 'personalLoans', label: 'Personal Loans' },
      { name: 'businessLoans', label: 'Business Loans' },
      { name: 'mortgages', label: 'Mortgages' },
      { name: 'receivables', label: 'Accounts Receivable' },
    ]
  },
  insurance: {
    title: 'Insurance',
    color: '#795548',
    fields: [
      { name: 'lifeInsurance', label: 'Life Insurance Value' },
      { name: 'endowmentPolicies', label: 'Endowment Policies Value' },
      { name: 'pensionValue', label: 'Pension Value' },
    ]
  }
};

export default function NetWorthCalculator() {
  const [values, setValues] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [showChart, setShowChart] = useState(false);

  const handleChange = (category, field, value) => {
    setValues(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: parseFloat(value) || 0
      }
    }));
  };

  const calculateCategoryTotal = (category) => {
    if (!values[category]) return 0;
    return Object.values(values[category]).reduce((sum, value) => sum + (value || 0), 0);
  };

  const calculateNetWorth = () => {
    const assets = ['property', 'commodities', 'cash', 'bonds', 'equities', 'insurance']
      .reduce((sum, category) => sum + calculateCategoryTotal(category), 0);
    const debts = calculateCategoryTotal('debt');
    return assets - debts;
  };

  const getChartData = () => {
    const categoryTotals = Object.keys(assetCategories).map(category => ({
      category: assetCategories[category].title,
      value: calculateCategoryTotal(category),
      color: assetCategories[category].color
    }));

    return {
      labels: categoryTotals.map(item => item.category),
      datasets: [
        {
          data: categoryTotals.map(item => Math.abs(item.value)),
          backgroundColor: categoryTotals.map(item => item.color),
          borderColor: categoryTotals.map(item => item.color),
          borderWidth: 1,
        },
      ],
    };
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Net Worth Calculator
      </Typography>
      <Typography variant="body1" paragraph>
        Calculate your net worth by entering values for different asset classes. All values should be in KES.
      </Typography>

      {Object.keys(assetCategories).map((category) => (
        <Accordion
          key={category}
          expanded={expanded === category}
          onChange={handleAccordionChange(category)}
          component={motion.div}
          whileHover={{ scale: 1.01 }}
          sx={{ mb: 2 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: `${assetCategories[category].color}15`,
              '&:hover': {
                backgroundColor: `${assetCategories[category].color}25`,
              },
            }}
          >
            <Typography sx={{ color: assetCategories[category].color, fontWeight: 'bold' }}>
              {assetCategories[category].title}
              {calculateCategoryTotal(category) > 0 && (
                <Typography component="span" sx={{ ml: 2 }}>
                  (KES {calculateCategoryTotal(category).toLocaleString()})
                </Typography>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {assetCategories[category].fields.map((field) => (
                <Grid item xs={12} sm={6} md={4} key={field.name}>
                  <TextField
                    fullWidth
                    label={field.label}
                    type="number"
                    variant="outlined"
                    value={values[category]?.[field.name] || ''}
                    onChange={(e) => handleChange(category, field.name, e.target.value)}
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1 }}>KES</Typography>,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 3,
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h5" gutterBottom>
            Total Net Worth
          </Typography>
          <Typography variant="h4" color={calculateNetWorth() >= 0 ? 'success.main' : 'error.main'}>
            KES {calculateNetWorth().toLocaleString()}
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<ChartIcon />}
          onClick={() => setShowChart(true)}
        >
          View Portfolio
        </Button>
      </Paper>

      <Dialog
        open={showChart}
        onClose={() => setShowChart(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Portfolio Distribution</DialogTitle>
        <DialogContent>
          <Box sx={{ height: 400, mt: 2 }}>
            <Pie data={getChartData()} options={{ maintainAspectRatio: false }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowChart(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
