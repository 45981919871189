import React from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Paper,
  AppBar,
  Toolbar,
} from '@mui/material';
import {
  TrendingUp,
  AccountBalance,
  Security,
  Timeline,
  MonetizationOn,
  ShowChart,
  Login as LoginIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const FeatureCard = ({ icon, title, description, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 3,
          height: '100%',
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          borderRadius: 2,
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {icon}
          <Typography variant="h6" sx={{ my: 2, color: 'primary.main', textAlign: 'center' }}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary" textAlign="center">
            {description}
          </Typography>
        </Box>
      </Paper>
    </motion.div>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #6B46C1 0%, #9F7AEA 100%)',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* AppBar with Login/Dashboard */}
      <AppBar 
        position="fixed" 
        elevation={0}
        sx={{ 
          background: 'transparent',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
              variant="h4" 
              sx={{ 
                color: 'white',
                fontWeight: 700,
                letterSpacing: '0.02em',
              }}
            >
              Hassed
            </Typography>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                ml: 2,
                color: 'rgba(255, 255, 255, 0.9)',
                display: { xs: 'none', sm: 'block' }
              }}
            >
              Wealth Management
            </Typography>
          </Box>
          {currentUser ? (
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.5)',
                '&:hover': {
                  borderColor: 'white',
                  bgcolor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
              onClick={() => navigate('/dashboard')}
              startIcon={<DashboardIcon />}
            >
              Go to Dashboard
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{
                color: 'white',
                borderColor: 'rgba(255, 255, 255, 0.5)',
                '&:hover': {
                  borderColor: 'white',
                  bgcolor: 'rgba(255, 255, 255, 0.1)'
                }
              }}
              onClick={() => navigate('/login')}
              startIcon={<LoginIcon />}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Animated background shapes */}
      <motion.div
        animate={{
          scale: [1, 1.2, 1],
          rotate: [0, 180, 360],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: 'linear',
        }}
        style={{
          position: 'absolute',
          top: '10%',
          right: '10%',
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: 'rgba(255, 255, 255, 0.1)',
          filter: 'blur(40px)',
        }}
      />
      <motion.div
        animate={{
          scale: [1.2, 1, 1.2],
          rotate: [360, 180, 0],
        }}
        transition={{
          duration: 25,
          repeat: Infinity,
          ease: 'linear',
        }}
        style={{
          position: 'absolute',
          bottom: '10%',
          left: '10%',
          width: '400px',
          height: '400px',
          borderRadius: '50%',
          background: 'rgba(255, 255, 255, 0.1)',
          filter: 'blur(50px)',
        }}
      />

      <Container maxWidth="lg" sx={{ pt: isMobile ? 12 : 16, pb: 8 }}>
        {/* Hero Section */}
        <Grid container spacing={4} alignItems="center" sx={{ mb: 12 }}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  mb: 3,
                  fontSize: isMobile ? '2.5rem' : '3.5rem',
                }}
              >
                Smart Wealth Management
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: 'rgba(255, 255, 255, 0.9)',
                  mb: 4,
                  fontSize: isMobile ? '1.2rem' : '1.5rem',
                }}
              >
                Take control of your financial future with our comprehensive wealth management tools
              </Typography>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                {currentUser ? (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/dashboard')}
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      px: 4,
                      py: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                  >
                    Go to Dashboard
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/signup')}
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      px: 4,
                      py: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                  >
                    Get Started
                  </Button>
                )}
              </motion.div>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
            >
              <Box
                component="img"
                src="/wealth-management-illustration.svg"
                alt="Wealth Management"
                sx={{
                  width: '100%',
                  maxWidth: 500,
                  height: 'auto',
                  display: 'block',
                  margin: '0 auto',
                }}
              />
            </motion.div>
          </Grid>
        </Grid>

        {/* Features Grid */}
        <Typography
          variant="h3"
          sx={{
            color: 'white',
            textAlign: 'center',
            mb: 8,
            fontSize: isMobile ? '2rem' : '2.5rem',
          }}
        >
          Our Features
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              icon: <TrendingUp sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Investment Tracking',
              description: 'Monitor your investments in real-time with our advanced tracking tools',
            },
            {
              icon: <AccountBalance sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Wealth Management',
              description: 'Comprehensive tools for managing and growing your wealth',
            },
            {
              icon: <Security sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Secure Planning',
              description: 'Plan your financial future with confidence using our secure platform',
            },
            {
              icon: <Timeline sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Performance Analytics',
              description: 'Detailed analytics to help you make informed financial decisions',
            },
            {
              icon: <MonetizationOn sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Return Calculator',
              description: 'Calculate potential returns across different investment vehicles',
            },
            {
              icon: <ShowChart sx={{ fontSize: 40, color: 'primary.main' }} />,
              title: 'Market Insights',
              description: 'Stay updated with the latest market trends and insights',
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={feature.title}>
              <FeatureCard {...feature} delay={index * 0.2} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
