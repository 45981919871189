import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

export default function UserProfileForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    age: '',
    employmentStatus: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setLoading(true);
      
      // Save user profile data to Firestore
      await setDoc(doc(db, 'users', currentUser.uid), {
        ...formData,
        email: currentUser.email,
        createdAt: new Date().toISOString()
      });

      navigate('/dashboard'); // Redirect to dashboard after profile setup
    } catch (error) {
      setError('Failed to save profile information. ' + error.message);
    }
    setLoading(false);
  };

  if (showWelcome) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Hassed, {formData.fullName}!
        </Typography>
        <Player
          autoplay
          keepLastFrame
          src="https://assets9.lottiefiles.com/packages/lf20_wcnjmdp1.json"
          style={{ height: '300px', width: '300px' }}
        />
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Tell Us About Yourself
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoFocus
              value={formData.fullName}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="age"
              label="Age"
              name="age"
              type="number"
              value={formData.age}
              onChange={handleChange}
              inputProps={{ min: 18, max: 100 }}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="employment-status-label">Employment Status</InputLabel>
              <Select
                labelId="employment-status-label"
                id="employmentStatus"
                name="employmentStatus"
                value={formData.employmentStatus}
                label="Employment Status"
                onChange={handleChange}
              >
                <MenuItem value="employed">Employed</MenuItem>
                <MenuItem value="self-employed">Self Employed</MenuItem>
                <MenuItem value="unemployed">Unemployed</MenuItem>
                <MenuItem value="student">Student</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Continue'}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
