import React, { useState } from 'react';
import { 
  Box, 
  Grid, 
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SavingsIcon from '@mui/icons-material/Savings';
import CalculateIcon from '@mui/icons-material/Calculate';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { motion } from 'framer-motion';
import FinancialCalculators from './FinancialCalculators';

const MotionPaper = motion(Paper);

const wealthSteps = [
  {
    title: 'TAX',
    icon: <AccountBalanceWalletIcon sx={{ fontSize: 40, color: '#4CAF50' }} />,
    color: '#4CAF50',
    content: [
      {
        question: "How to get a Tax Compliance Certificate?",
        answer: [
          "1. Prerequisites:",
          "• Valid KRA PIN",
          "• iTax registration",
          "• Up-to-date tax returns filing",
          "• All tax obligations met",
          "",
          "2. Application Process:",
          "• Log into iTax portal",
          "• Select 'Application for Tax Compliance Certificate'",
          "• Fill in required details",
          "• Submit application",
          "• Pay application fee (KES 1,000)",
          "",
          "3. Processing Time:",
          "• 1-2 working days if compliant",
          "• Valid for 12 months",
          "• Downloadable from iTax portal"
        ]
      },
      {
        question: "What are the statutory payments and filing deadlines for different industries?",
        answer: [
          "Real Estate Industry:",
          "• Monthly Rental Income Tax: 10th of following month",
          "• Capital Gains Tax: 30 days after transaction",
          "• Property Rates: Annually",
          "• Land Rent: Annually",
          "",
          "Company Requirements:",
          "• PAYE: 9th of following month",
          "• Corporate Tax: Quarterly installments",
          "• VAT: 20th of following month",
          "• NHIF: 9th of following month",
          "• NSSF: 15th of following month",
          "• Annual Returns: Within 6 months after year end"
        ]
      },
      {
        question: "How does filing your taxes affect your credit rating?",
        answer: [
          "Impact on Credit Score:",
          "• Tax compliance status affects CRB rating",
          "• TCC required for loan applications",
          "• KRA debt reports to CRB",
          "",
          "Benefits of Compliance:",
          "• Better loan terms",
          "• Faster loan processing",
          "• Higher credit limits",
          "• Business tender eligibility",
          "",
          "Consequences of Non-compliance:",
          "• Negative CRB listing",
          "• Loan application rejection",
          "• Higher interest rates",
          "• Limited credit access"
        ]
      },
      {
        question: "How to do audits and be investor ready?",
        answer: [
          "Preparation Steps:",
          "• Maintain proper books of accounts",
          "• Regular financial statements",
          "• Document all transactions",
          "• Keep supporting documents",
          "",
          "Required Documents:",
          "• Financial statements",
          "• Bank statements",
          "• Tax returns and payments",
          "• Business licenses",
          "• Contracts and agreements",
          "",
          "Best Practices:",
          "• Regular internal audits",
          "• Professional accounting software",
          "• Qualified accountant",
          "• Clear financial policies"
        ]
      },
      {
        question: "How does company structure affect your taxes?",
        answer: [
          "Sole Proprietorship:",
          "• Personal income tax rates apply",
          "• Simpler tax requirements",
          "• Personal liability for tax debt",
          "",
          "Limited Company:",
          "• Corporate tax rate: 30%",
          "• Separate legal entity",
          "• More tax planning options",
          "• Tax-deductible expenses",
          "",
          "Partnerships:",
          "• Partners taxed individually",
          "• Profit sharing affects tax",
          "• Joint tax liability"
        ]
      },
      {
        question: "What tax incentives has the government offered?",
        answer: [
          "Manufacturing Sector:",
          "• 150% tax deduction for apprenticeship",
          "• Investment deduction on buildings",
          "• Reduced power tariffs",
          "",
          "Agriculture Sector:",
          "• Farm works deduction",
          "• VAT exemption on inputs",
          "• Capital allowances",
          "",
          "Technology Sector:",
          "• R&D deduction: 100%",
          "• Training expense deduction",
          "",
          "General Incentives:",
          "• Capital allowances",
          "• Industrial building allowance",
          "• Export processing zones benefits"
        ]
      },
      {
        question: "What is an EPZ and how to register?",
        answer: [
          "EPZ Definition:",
          "• Designated zones for export-oriented businesses",
          "• Located in Athi River, Mombasa, Nairobi, Kilifi",
          "",
          "Benefits:",
          "• 10-year corporate tax holiday",
          "• 25% tax for next 10 years",
          "• Import duty exemption",
          "• VAT exemption",
          "",
          "Registration Requirements:",
          "• Export 80% of production",
          "• Minimum investment: USD 100,000",
          "• Application to EPZA",
          "• Business plan submission",
          "• Environmental impact assessment"
        ]
      },
      {
        question: "How to file taxes?",
        answer: [
          "iTax Registration:",
          "• Create KRA PIN",
          "• Activate iTax account",
          "• Get login credentials",
          "",
          "Filing Steps:",
          "1. Log into iTax portal",
          "2. Select return type",
          "3. Download return template",
          "4. Fill required information",
          "5. Upload completed return",
          "6. Submit and get acknowledgment",
          "",
          "Payment Methods:",
          "• KRA Pay Bill: 572572",
          "• Bank transfers",
          "• Agency banking",
          "• E-slip generation"
        ]
      }
    ],
    averageCosts: {
      'TCC Application': 'KES 1,000',
      'Tax Return Filing (Individual)': 'KES 2,000 - 5,000',
      'Tax Return Filing (Company)': 'KES 10,000 - 50,000',
      'Tax Audit Preparation': 'KES 50,000 - 200,000',
      'Tax Consultant (per hour)': 'KES 3,000 - 15,000',
      'EPZ License': 'KES 50,000'
    }
  },
  {
    title: 'LEGAL',
    icon: <TrendingUpIcon sx={{ fontSize: 40, color: '#2196F3' }} />,
    color: '#2196F3',
    content: [
      {
        question: "How to register a company in Kenya?",
        answer: [
          "Step-by-Step Process:",
          "1. Name Search and Reservation",
          "• Cost: KES 150 per name",
          "• Processing time: 1-2 days",
          "• Valid for 30 days",
          "",
          "2. Required Documents:",
          "• CR1 - Company Registration Form",
          "• CR2 - Memorandum and Articles of Association",
          "• Copy of ID/Passport for all directors",
          "• KRA PIN certificates for all directors",
          "• Passport photos",
          "",
          "3. Registration Process:",
          "• Submit documents to Registrar of Companies",
          "• Pay registration fees (KES 10,000)",
          "• Processing time: 7-14 days",
          "",
          "4. Post-Registration:",
          "• Obtain KRA PIN for company",
          "• Register for VAT (if applicable)",
          "• Apply for business permits"
        ]
      },
      {
        question: "How to acquire a lawyer in Kenya?",
        answer: [
          "Finding a Qualified Lawyer:",
          "• Law Society of Kenya website directory",
          "• Bar directory search",
          "• Professional referrals",
          "",
          "Verification Process:",
          "• Check LSK membership status",
          "• Verify practicing certificate",
          "• Review specialization areas",
          "",
          "Cost Implications:",
          "• Consultation: KES 5,000 - 15,000",
          "• Retainer: KES 50,000 - 200,000/month",
          "• Case-based fees vary by complexity",
          "",
          "Important Considerations:",
          "• Experience in relevant field",
          "• Professional reputation",
          "• Clear fee structure",
          "• Communication style"
        ]
      },
      {
        question: "How much are the court fees?",
        answer: [
          "Civil Cases:",
          "• Filing fees: KES 1,500 - 25,000",
          "• Service fees: KES 500 per service",
          "• Hearing fees: KES 300 per hearing",
          "",
          "Commercial Cases:",
          "• Filing fees: 1% of claim value",
          "• Minimum: KES 20,000",
          "• Maximum: KES 100,000",
          "",
          "Land Cases:",
          "• Filing fees: KES 2,000 - 50,000",
          "• Survey fees if required",
          "",
          "Additional Costs:",
          "• Certified copies: KES 100 per page",
          "• Affidavit filing: KES 200",
          "• Court orders: KES 500"
        ]
      },
      {
        question: "What compliance is required for different industries?",
        answer: [
          "General Requirements:",
          "• Business registration",
          "• Tax compliance certificate",
          "• County business permit",
          "• Fire safety certificate",
          "",
          "Manufacturing:",
          "• NEMA certification",
          "• Factory registration",
          "• Health and safety compliance",
          "• Export licenses (if applicable)",
          "",
          "Financial Services:",
          "• CBK licensing",
          "• CMA registration",
          "• IRA certification for insurance",
          "",
          "Healthcare:",
          "• Medical board registration",
          "• Pharmacy board permits",
          "• Health inspection certificates",
          "",
          "Hospitality:",
          "• Tourism license",
          "• Health certificates",
          "• Liquor license (if applicable)",
          "",
          "Construction:",
          "• NCA registration",
          "• Environmental impact assessment",
          "• County approvals"
        ]
      },
      {
        question: "Which company structures are best suited for different investment periods and dissolution?",
        answer: [
          "Short-term Investment (1-3 years):",
          "• Sole proprietorship",
          "• Simple partnership",
          "• Easy setup and dissolution",
          "• Lower compliance costs",
          "",
          "Medium-term (3-7 years):",
          "• Limited Liability Company",
          "• Private company limited by shares",
          "• Better tax planning options",
          "",
          "Long-term (7+ years):",
          "• Public limited company",
          "• Holding company structure",
          "• Complex corporate structure",
          "",
          "Dissolution Process:",
          "• Voluntary dissolution",
          "• Members' voluntary winding up",
          "• Creditors' voluntary winding up",
          "• Court winding up"
        ]
      },
      {
        question: "Where should you file different types of court cases?",
        answer: [
          "Employment Disputes:",
          "• Employment and Labour Relations Court",
          "• Located in major towns",
          "• Handles all employment matters",
          "",
          "Land Disputes:",
          "• Environment and Land Court",
          "• Handles all land/property matters",
          "• Located in county headquarters",
          "",
          "Commercial Disputes:",
          "• Commercial Division of High Court",
          "• Claims above KES 20M",
          "• Complex business matters",
          "",
          "Small Claims:",
          "• Small Claims Court",
          "• Claims below KES 1M",
          "• Fast-track resolution"
        ]
      },
      {
        question: "Who is a next of kin, beneficiary and their legal capacity?",
        answer: [
          "Next of Kin:",
          "• Closest living relative",
          "• Order of priority by law",
          "• Limited legal rights",
          "",
          "Beneficiary:",
          "• Named in will/trust",
          "• Direct right to inherit",
          "• Can be any person/entity",
          "",
          "Legal Capacity:",
          "Next of Kin:",
          "• Can apply for letters of administration",
          "• Access to death certificate",
          "• Hospital decisions",
          "",
          "Beneficiary:",
          "• Right to inherit as specified",
          "• Can contest will",
          "• Can transfer inheritance"
        ]
      },
      {
        question: "What does the law recognize as marriage and certification process?",
        answer: [
          "Recognized Marriage Types:",
          "• Civil marriage",
          "• Christian marriage",
          "• Islamic marriage",
          "• Hindu marriage",
          "• Customary marriage",
          "",
          "Registration Process:",
          "1. Notice of intention (21 days)",
          "2. Required documents submission",
          "3. Marriage ceremony",
          "4. Certificate issuance",
          "",
          "Costs:",
          "• Notice filing: KES 600",
          "• Registration: KES 3,900",
          "• Certificate: KES 1,000"
        ]
      },
      {
        question: "Who is a person with disability and registration bodies?",
        answer: [
          "Definition:",
          "• Physical impairment",
          "• Mental impairment",
          "• Congenital/acquired condition",
          "",
          "Registration Bodies:",
          "1. National Council for Persons with Disabilities (NCPWD)",
          "• Main registration body",
          "• Issues disability cards",
          "• Coordinates services",
          "",
          "2. Kenya Society for the Blind (KSB)",
          "• Visual impairment registration",
          "• Specialized services",
          "",
          "Benefits:",
          "• Tax exemptions",
          "• Educational support",
          "• Employment quota",
          "• Healthcare benefits"
        ]
      },
      {
        question: "How does a foreigner process a work permit and costs?",
        answer: [
          "Types of Permits:",
          "• Class A: Prospecting/Mining",
          "• Class B: Agriculture",
          "• Class C: Prescribed profession",
          "• Class D: Employment",
          "• Class G: Specific trade/business",
          "",
          "Requirements:",
          "• Valid passport",
          "• Cover letter from employer",
          "• Academic certificates",
          "• Professional certificates",
          "• Police clearance",
          "• Job advertisement proof",
          "",
          "Process:",
          "1. Online application",
          "2. Document submission",
          "3. Security clearance",
          "4. Permit committee review",
          "",
          "Costs:",
          "• Class D permit: KES 200,000",
          "• Processing fee: KES 10,000",
          "• Renewal: KES 100,000"
        ]
      }
    ],
    averageCosts: {
      'Company Registration': 'KES 10,000 - 30,000',
      'Legal Consultation': 'KES 5,000 - 15,000/hour',
      'Court Filing': 'KES 1,500 - 100,000',
      'Marriage Certificate': 'KES 5,500',
      'Work Permit': 'KES 200,000',
      'Disability Registration': 'Free'
    }
  },
  {
    title: 'INCOME',
    icon: <SavingsIcon sx={{ fontSize: 40, color: '#4CAF50' }} />,
    color: '#4CAF50',
    content: [
      {
        question: 'How much is the commission for different businesses?',
        answer: `Business Commission Guide:
        1. Real Estate:
           • Property Sales: 2-5% of sale value
           • Rental Management: 7-10% monthly rent
           • Land Sales: 2.5-5% of value
           • Commercial Leasing: 8.33% of annual rent
        
        2. Insurance:
           • Life Insurance: 20-35% first year premium
           • General Insurance: 10-20% of premium
           • Medical Insurance: 15-25% first year
           • Renewal Commission: 2-5% annually
        
        3. Financial Products:
           • Mutual Funds: 0.5-2% annual fee
           • Stock Brokerage: 1.5-2.1% per trade
           • Forex Trading: 2-3 pips spread
           • Investment Advisory: 1-2% AUM
        
        4. Retail & Distribution:
           • FMCG Products: 10-25% margin
           • Electronics: 15-30% margin
           • Vehicle Sales: 3-7% commission
           • Franchise: 5-10% of revenue
        
        Cost: Business registration: KES 10,000-30,000`
      },
      {
        question: 'What products do banks have that can better preserve your value?',
        answer: `Banking Value Preservation:
        1. Fixed Deposit Accounts:
           • Interest: 6-9% p.a.
           • Minimum: KES 50,000
           • Term: 3-12 months
           • Early withdrawal penalties
        
        2. Money Market Funds:
           • Returns: 8-11% p.a.
           • Minimum: KES 5,000
           • Daily interest computation
           • High liquidity
        
        3. Foreign Currency Accounts:
           • USD, EUR, GBP options
           • Protects against KES depreciation
           • Minimum: USD 100
           • Zero conversion fees
        
        4. Structured Products:
           • Returns: 10-15% p.a.
           • Capital protection
           • Minimum: KES 250,000
           • Customized terms
        
        Cost: Account maintenance: KES 0-1,000 monthly`
      },
      {
        question: 'How does inflation affect your cash?',
        answer: `Inflation Impact Analysis:
        1. Purchasing Power:
           • Current inflation: 6-8% annually
           • KES 100 in 2020 = KES 80 today
           • Savings depreciate if below inflation
           • Real returns = Nominal - Inflation
        
        2. Protection Strategies:
           • Investment in real assets
           • Index-linked investments
           • Foreign currency holdings
           • Inflation-beating returns
        
        3. Risk Areas:
           • Fixed deposits below inflation
           • Cash holdings
           • Fixed-income investments
           • Pension with no indexing
        
        4. Mitigation Methods:
           • Diversified portfolio
           • Regular rebalancing
           • Inflation-linked securities
           • Real estate investments
        
        Cost: Financial advisory: KES 5,000-15,000`
      },
      {
        question: 'How do wealth instruments like MMF, Bonds, FDRs, T-bills and ETFs factor into your wealth journey?',
        answer: `Wealth Instruments Guide:
        1. Money Market Funds (MMF):
           • Low risk, steady returns
           • 8-11% annual return
           • High liquidity
           • Start from KES 5,000
        
        2. Government Bonds:
           • 2-year: 10-12% p.a.
           • 5-year: 11-13% p.a.
           • 10-year: 12-14% p.a.
           • Minimum: KES 50,000
        
        3. Fixed Deposit Receipts (FDRs):
           • 3-month: 6-7% p.a.
           • 6-month: 7-8% p.a.
           • 12-month: 8-9% p.a.
           • Minimum: KES 100,000
        
        4. Treasury Bills:
           • 91-day: 7-9% p.a.
           • 182-day: 8-10% p.a.
           • 364-day: 9-11% p.a.
           • Minimum: KES 100,000
        
        5. ETFs (NSE):
           • Track market indices
           • Lower management fees
           • High liquidity
           • Market-linked returns
        
        Cost: Broker fees: 0.12-2.1% per transaction`
      },
      {
        question: 'What are the returns in the known tools for investments i.e. real estate, lands, MMF, Insurance, Stock market, and the period of ROI?',
        answer: `Investment Returns Analysis:
        1. Real Estate:
           • Rental yield: 5-8% annually
           • Capital appreciation: 10-15% annually
           • ROI period: 8-12 years
           • Location dependent
        
        2. Land:
           • Capital gains: 12-20% annually
           • ROI period: 5-10 years
           • Location dependent
           • Development potential
        
        3. Money Market Funds:
           • Returns: 8-11% annually
           • ROI: Immediate (daily returns)
           • Low risk profile
           • High liquidity
        
        4. Insurance Investment:
           • Returns: 5-7% annually
           • ROI period: 10-15 years
           • With life coverage
           • Tax benefits
        
        5. Stock Market:
           • Returns: 12-18% annually
           • ROI period: 3-5 years
           • Higher volatility
           • Dividend income
        
        Cost: Investment advisory: KES 10,000-50,000`
      },
      {
        question: 'Government incentives for youth business owners to do business with government e.g. AGPO Certificate',
        answer: `AGPO Benefits & Process:
        1. Eligibility:
           • Youth (18-35 years)
           • Registered business
           • Tax compliance
           • Bank account
        
        2. Benefits:
           • 30% government tenders reserved
           • No bid bond required
           • Preferential treatment
           • Prompt payment guarantee
        
        3. Registration Process:
           • Business registration
           • KRA PIN
           • AGPO portal registration
           • Document submission
        
        4. Required Documents:
           • ID copy
           • Business registration
           • Tax compliance
           • Bank details
        
        Cost: Registration is free, Document processing: KES 2,000-5,000`
      },
      {
        question: 'Saccos and credit intuitions to give access to credit and at what cost, also what is the process of getting registered and what are the incentives/benefits of each Sacco.',
        answer: `SACCO Guide:
        1. Registration Process:
           • Fill membership form
           • Provide ID/KRA PIN
           • Initial share capital
           • Monthly contributions
        
        2. Benefits:
           • Lower interest rates (10-14% p.a.)
           • Dividends (8-12% annually)
           • Quick loan processing
           • Flexible collateral
        
        3. Popular SACCOs:
           • Mwalimu National
           • Stima SACCO
           • Harambee SACCO
           • Unaitas
        
        4. Credit Products:
           • Development loans: 3x savings
           • Emergency loans: 1x savings
           • School fees loans
           • Asset financing
        
        5. Requirements:
           • Minimum monthly contribution
           • Share capital
           • Guarantors for loans
           • Regular savings
        
        Cost: Registration: KES 1,000-5,000, Monthly contribution: KES 1,000+`
      },
    ],
    averageCosts: {
      'Business Registration': 'KES 10,000 - 30,000',
      'Investment Advisory': 'KES 10,000 - 50,000',
      'SACCO Registration': 'KES 1,000 - 5,000',
      'Financial Software': 'KES 1,000 - 5,000/month'
    }
  },
  {
    title: 'CALCULATORS',
    icon: <CalculateIcon sx={{ fontSize: 40, color: '#009688' }} />,
    color: '#009688',
    content: [
      {
        question: 'Financial Calculators',
        answer: null // This will be handled by the FinancialCalculators component
      }
    ],
    averageCosts: {
      'Financial Planning': 'Free',
      'Investment Advisory': 'Varies by portfolio size',
      'Tax Planning': 'Based on complexity'
    }
  },
  {
    title: 'ASSET TRANSFER',
    icon: <SwapHorizIcon sx={{ fontSize: 40, color: '#FF9800' }} />,
    color: '#FF9800',
    content: [
      {
        question: 'Why is it important to do asset transfers in families?',
        answer: `Family Asset Transfer Importance:
        1. Wealth Preservation:
           • Minimizes inheritance disputes
           • Maintains family harmony
           • Preserves generational wealth
           • Ensures fair distribution
        
        2. Tax Benefits:
           • Reduced inheritance tax
           • Gift tax advantages
           • Property transfer relief
           • Capital gains planning
        
        3. Legal Protection:
           • Clear ownership records
           • Protected inheritance rights
           • Reduced litigation risk
           • Documented succession
        
        4. Family Benefits:
           • Financial security
           • Business continuity
           • Knowledge transfer
           • Legacy preservation
        
        Cost: Legal consultation: KES 15,000-50,000`
      },
      {
        question: 'How do you buy and sell properties?',
        answer: `Property Transaction Guide:
        1. Buying Process:
           • Property search
           • Title verification
           • Sale agreement
           • Land search
           • Payment process
        
        2. Due Diligence:
           • Ownership verification
           • Land rate clearance
           • Encumbrance check
           • Survey confirmation
        
        3. Legal Requirements:
           • Transfer documents
           • Stamp duty payment
           • Registration
           • Title transfer
        
        4. Professional Services:
           • Lawyer
           • Surveyor
           • Valuer
           • Land registrar
        
        Cost: 
        • Legal fees: 1.5-2.5% of property value
        • Stamp duty: 4% urban, 2% rural`
      },
      {
        question: 'How do you verify that the process is complete?',
        answer: `Verification Process:
        1. Documentation Check:
           • Title deed transfer
           • Payment receipts
           • Transfer tax clearance
           • Registration confirmation
        
        2. Legal Verification:
           • Land registry search
           • Rates clearance
           • Transfer completion
           • Official searches
        
        3. Physical Verification:
           • Property handover
           • Utility transfer
           • Occupancy rights
           • Key transfer
        
        4. Final Steps:
           • Bank notifications
           • Insurance updates
           • Utility registration
           • Record keeping
        
        Cost: Verification fees: KES 5,000-15,000`
      },
      {
        question: 'Where do you go in the event of a dispute?',
        answer: `Dispute Resolution Guide:
        1. Initial Steps:
           • Land registrar
           • County land office
           • Alternative dispute resolution
           • Legal consultation
        
        2. Legal Options:
           • Environment & Land Court
           • High Court
           • Court of Appeal
           • Supreme Court
        
        3. Alternative Methods:
           • Mediation
           • Arbitration
           • Traditional resolution
           • Family council
        
        4. Professional Help:
           • Property lawyers
           • Land surveyors
           • Valuers
           • Mediators
        
        Cost: Legal fees: KES 50,000-500,000+`
      },
      {
        question: 'What is the tax implication around different assets?',
        answer: `Tax Implications Guide:
        1. Property:
           • Capital gains tax: 5%
           • Stamp duty: 2-4%
           • Rental income tax: 10-30%
           • Property rates
        
        2. Shares/Stocks:
           • Capital gains tax: 5%
           • Dividend tax: 5%
           • Transfer fees
           • Broker commissions
        
        3. Business Assets:
           • VAT: 16%
           • Corporate tax: 30%
           • Transfer pricing
           • Asset disposal tax
        
        4. Personal Assets:
           • Gift tax
           • Estate duty
           • Transfer fees
           • Registration costs
        
        Cost: Tax advisory: KES 10,000-50,000`
      },
      {
        question: 'Differences of properties being owned by an individual VS a company? And their pros and cons',
        answer: `Ownership Comparison:
        1. Individual Ownership:
           Pros:
           • Simple management
           • Direct control
           • Lower setup costs
           • Quick decisions
           
           Cons:
           • Personal liability
           • Limited tax benefits
           • Succession challenges
           • Higher personal tax
        
        2. Company Ownership:
           Pros:
           • Limited liability
           • Tax advantages
           • Easier transfer
           • Professional image
           
           Cons:
           • Higher setup costs
           • Complex compliance
           • Annual returns
           • More paperwork
        
        Cost: Company setup: KES 30,000-100,000`
      },
      {
        question: 'TVET and skill transfer institutions for communities to preserve and improve their numbers.',
        answer: `TVET Framework:
        1. Available Institutions:
           • Technical colleges
           • Vocational centers
           • Community colleges
           • Apprenticeship programs
        
        2. Skills Focus:
           • Technical trades
           • Digital skills
           • Business management
           • Agricultural practices
        
        3. Community Benefits:
           • Employment creation
           • Economic growth
           • Youth development
           • Knowledge preservation
        
        4. Implementation:
           • Needs assessment
           • Resource mobilization
           • Program development
           • Impact measurement
        
        Cost: Training: KES 30,000-150,000 per program`
      },
      {
        question: 'How to do an asset valuation and where to get it done?',
        answer: `Valuation Guide:
        1. Professional Valuers:
           • Registered valuers
           • Property consultants
           • Financial advisors
           • Industry experts
        
        2. Valuation Methods:
           • Market comparison
           • Income approach
           • Cost approach
           • Asset-based method
        
        3. Required Documents:
           • Title documents
           • Building plans
           • Income statements
           • Historical data
        
        4. Valuation Process:
           • Site inspection
           • Document review
           • Market analysis
           • Report preparation
        
        Cost: Valuation fee: 0.1-0.25% of asset value`
      }
    ],
    averageCosts: {
      'Legal Consultation': 'KES 15,000 - 50,000',
      'Property Transfer': '1.5-2.5% of property value',
      'Dispute Resolution': 'KES 50,000 - 500,000+',
      'Asset Valuation': '0.1-0.25% of asset value'
    }
  },
  {
    title: 'CHARITY',
    icon: <AccountBalanceWalletIcon sx={{ fontSize: 40, color: '#E91E63' }} />,
    color: '#E91E63',
    content: [
      {
        question: 'What does the law say about gifts and donations?',
        answer: `Legal Framework for Gifts and Donations:
        1. Tax Implications:
           • Donations to registered charities are tax-deductible
           • Maximum deduction of 10% of taxable income
           • Gift tax exemption up to KES 500,000 annually
           • Required documentation for large donations
        
        2. Legal Requirements:
           • Written agreements for substantial gifts
           • Proper recording and documentation
           • Disclosure of donor information
           • Compliance with anti-money laundering laws
        
        3. Charitable Organizations:
           • Must be registered with NGO Board
           • Annual reporting of donations
           • Transparent use of funds
           • Proper receipting system
        
        4. Corporate Donations:
           • Board approval for large donations
           • CSR policy compliance
           • Public disclosure requirements
           • Shareholder considerations
        
        Cost: Legal documentation: KES 10,000-30,000 per major gift`
      },
      {
        question: 'Having a fund for your CSR, Religious beliefs, Aid',
        answer: `Fund Management Framework:
        1. CSR Fund Structure:
           • Dedicated bank account
           • Annual budget allocation
           • Project-based disbursement
           • Impact measurement metrics
        
        2. Religious Donations:
           • Zakat calculation (2.5% of wealth)
           • Structured giving programs
           • Community development funds
           • Religious institution support
        
        3. Aid Distribution:
           • Need assessment criteria
           • Beneficiary verification
           • Emergency response funds
           • Regular aid programs
        
        4. Fund Management:
           • Professional administration
           • Regular audits
           • Transparent reporting
           • Stakeholder engagement
        
        Cost: Fund setup and management: 5-10% of fund value annually`
      },
      {
        question: 'How to plan better for emergencies especially ones around social responsibilities i.e. WhatsApp groups, charity events, Willful compassion',
        answer: `Emergency Planning Framework:
        1. Communication Systems:
           • WhatsApp group guidelines
           • Emergency contact lists
           • Response protocols
           • Information verification
        
        2. Event Management:
           • Quick response teams
           • Resource mobilization
           • Volunteer coordination
           • Logistics planning
        
        3. Financial Preparation:
           • Emergency fund setup
           • Quick disbursement process
           • Mobile money integration
           • Expense tracking
        
        4. Community Engagement:
           • Network building
           • Regular drills
           • Resource mapping
           • Capacity building
        
        Cost: Emergency fund: Minimum KES 100,000 recommended`
      },
      {
        question: 'How do Chamas work and what they can morph into? Also where they could put their money into VS the returns on investments on each item.',
        answer: `Chama Investment Guide:
        1. Basic Structure:
           • Regular contributions
           • Shared leadership
           • Clear constitution
           • Proper record keeping
        
        2. Investment Options:
           • Real Estate (ROI: 10-15% annually)
           • Government Securities (ROI: 8-12%)
           • Stock Market (ROI: 12-20%)
           • Business Ventures (ROI: 15-25%)
        
        3. Evolution Paths:
           • Investment groups
           • Limited companies
           • SACCOs
           • Investment clubs
        
        4. Risk Management:
           • Diversification
           • Professional advice
           • Legal compliance
           • Regular audits
        
        Cost: Registration: KES 5,000-15,000, Management: 2-5% of portfolio`
      }
    ],
    averageCosts: {
      'Legal Documentation': 'KES 10,000 - 30,000',
      'Fund Management': '5-10% of fund value',
      'Emergency Fund': 'KES 100,000 minimum',
      'Chama Setup': 'KES 5,000 - 15,000'
    }
  },
  {
    title: 'INHERITANCE',
    icon: <SavingsIcon sx={{ fontSize: 40, color: '#9C27B0' }} />,
    color: '#9C27B0',
    content: [
      {
        question: 'How long does the inheritance process take?',
        answer: `Inheritance Timeline:
        1. Initial Phase (1-2 months):
           • Filing of death certificate
           • Preliminary asset inventory
           • Notification of beneficiaries
        
        2. Documentation (2-3 months):
           • Will verification
           • Asset valuation
           • Tax assessment
        
        3. Legal Process (3-6 months):
           • Court applications
           • Grant of probate
           • Letters of administration
        
        4. Distribution (1-2 months):
           • Asset transfer
           • Tax clearance
           • Final accounts

        Cost: Legal fees typically range from KES 50,000-200,000`
      },
      {
        question: 'What happens during inheritance disputes?',
        answer: `Dispute Handling:
        1. Common Disputes:
           • Will validity
           • Asset distribution
           • Beneficiary claims
           • Administrator actions
        
        2. Resolution Methods:
           • Family mediation
           • Legal arbitration
           • Court litigation
           • Traditional resolution
        
        3. Prevention Steps:
           • Clear will writing
           • Asset documentation
           • Family communication
           • Professional advice
        
        4. Asset Organization:
           • Digital asset list
           • Property documents
           • Investment records
           • Debt information
        
        Cost: Planning services: KES 50,000-200,000`
      },
      {
        question: 'What happens if the family doesnt know the extent of wealth?',
        answer: `Asset Discovery Process:
        1. Document Search:
           • Bank statements
           • Property records
           • Company searches
           • Investment accounts
        
        2. Professional Help:
           • Asset tracers
           • Forensic accountants
           • Private investigators
           • Legal researchers
        
        3. Official Channels:
           • Bank inquiries
           • Land searches
           • Company registry
           • Share registry
        
        4. Digital Assets:
           • Email accounts
           • Online banking
           • Digital wallets
           • Social media
        
        Cost: Professional services: KES 100,000-500,000`
      },
      {
        question: 'What are wills and trusts, and how do they work legally?',
        answer: `Legal Framework:
        1. Will Requirements:
           • Written document
           • Signed by testator
           • Two witnesses
           • Mental capacity
           • Free from coercion
        
        2. Trust Types:
           • Living Trust
           • Testamentary Trust
           • Charitable Trust
           • Family Trust
        
        3. Legal Effects:
           • Will: Effective after death
           • Trust: Can be immediate
           • Override intestacy laws
           • Tax implications vary
        
        4. Key Benefits:
           • Asset protection
           • Tax efficiency
           • Privacy
           • Succession planning
        
        Cost: Will drafting: KES 15,000-50,000, Trust setup: KES 50,000-200,000`
      },
      {
        question: 'Where should you incorporate your trust - Kenya or overseas?',
        answer: `Trust Location Comparison:
        1. Kenyan Trust:
           • Local jurisdiction
           • Easier administration
           • 30% corporate tax
           • Familiar legal system
        
        2. Overseas Options:
           • Mauritius (3% tax)
           • Dubai (0% tax)
           • Jersey (0% tax)
           • Singapore (17% tax)
        
        3. Considerations:
           • Asset location
           • Tax efficiency
           • Privacy needs
           • Management costs
        
        4. Requirements:
           • Trust deed
           • Trustee appointment
           • Asset transfer
           • Compliance setup
        
        Cost: Kenya: KES 50,000-200,000, Overseas: USD 5,000-15,000`
      },
      {
        question: 'What are the cost implications for inheritance?',
        answer: `Cost Breakdown:
        1. Legal Costs:
           • Court filing: KES 2,000-5,000
           • Lawyer fees: 1.5-5% of estate
           • Document preparation: KES 10,000-30,000
        
        2. Administrative:
           • Death certificate: KES 500
           • Gazette notice: KES 2,500
           • Property search: KES 5,000
           • Valuation: 0.1-0.25% of assets
        
        3. Transfer Fees:
           • Land: 4% of value
           • Vehicles: KES 5,000
           • Shares: 0.1-0.3%
           • Bank accounts: Free
        
        4. Professional Fees:
           • Accountant: KES 20,000-50,000
           • Valuers: KES 10,000-100,000
           • Asset tracers: KES 50,000+
        
        Total costs: 5-10% of estate value`
      },
      {
        question: 'What happens during inheritance disputes?',
        answer: `Dispute Handling:
        1. Common Disputes:
           • Will validity
           • Asset distribution
           • Beneficiary claims
           • Administrator actions
        
        2. Resolution Methods:
           • Family mediation
           • Legal arbitration
           • Court litigation
           • Traditional resolution
        
        3. Timeline Impact:
           • Mediation: 1-3 months
           • Court case: 1-5 years
           • Appeals: 1-2 years
        
        4. Cost Implications:
           • Legal fees increase
           • Asset value may reduce
           • Extended administration
        
        Cost: Dispute resolution: KES 100,000-500,000+`
      },
      {
        question: 'What avenues can one pursue to resolve disputes?',
        answer: `Resolution Options:
        1. Traditional Methods:
           • Family elders
           • Religious leaders
           • Community mediation
           • Clan meetings
        
        2. Alternative Dispute Resolution:
           • Mediation
           • Arbitration
           • Negotiation
           • Conciliation
        
        3. Legal System:
           • Magistrate courts
           • High Court
           • Court of Appeal
           • Supreme Court
        
        4. Specialized Forums:
           • Family Division
           • Kadhi Courts
           • Land Tribunal
           • Probate Court
        
        Cost: Mediation: KES 50,000-150,000, Court: KES 100,000+`
      },
      {
        question: 'How does one plan early for inheritance?',
        answer: `Early Planning Steps:
        1. Documentation:
           • Write valid will
           • Create asset inventory
           • Update beneficiaries
           • Store documents safely
        
        2. Legal Structures:
           • Trust setup
           • Company formation
           • Joint ownership
           • Life insurance
        
        3. Communication:
           • Family meetings
           • Professional advice
           • Clear intentions
           • Regular updates
        
        4. Asset Organization:
           • Digital asset list
           • Property documents
           • Investment records
           • Debt information
        
        Cost: Planning services: KES 50,000-200,000`
      },
      {
        question: 'What happens if the family doesnt know the extent of wealth?',
        answer: `Asset Discovery Process:
        1. Document Search:
           • Bank statements
           • Property records
           • Company searches
           • Investment accounts
        
        2. Professional Help:
           • Asset tracers
           • Forensic accountants
           • Private investigators
           • Legal researchers
        
        3. Official Channels:
           • Bank inquiries
           • Land searches
           • Company registry
           • Share registry
        
        4. Digital Assets:
           • Email accounts
           • Online banking
           • Digital wallets
           • Social media
        
        Cost: Professional services: KES 100,000-500,000`
      },
      {
        question: 'How to recover inheritance assets?',
        answer: `Asset Recovery Steps:
        1. Legal Process:
           • Court orders
           • Police assistance
           • Bank freezes
           • Property cautions
        
        2. Documentation:
           • Ownership proof
           • Court orders
           • Identity documents
           • Succession papers
        
        3. Recovery Methods:
           • Direct claiming
           • Legal enforcement
           • Negotiation
           • Court action
        
        4. Timeline:
           • Local assets: 1-6 months
           • Foreign assets: 6-24 months
           • Disputed: 1-5 years
        
        Cost: Recovery costs: 10-30% of asset value`
      },
      {
        question: 'What is the role of marriage in inheritance?',
        answer: `Marriage Impact:
        1. Types of Marriage:
           • Civil marriage
           • Customary marriage
           • Islamic marriage
           • Hindu marriage
        
        2. Spouse Rights:
           • Life interest
           • Personal effects
           • Matrimonial home
           • Joint assets
        
        3. Multiple Marriages:
           • Polygamy effects
           • Children's rights
           • Property division
           • Maintenance
        
        4. Divorce Impact:
           • Settlement terms
           • Court orders
           • Children's provision
           • Property rights
        
        Cost: Legal consultation: KES 15,000-50,000`
      },
      {
        question: 'How to conduct estate valuation?',
        answer: `Valuation Process:
        1. Asset Categories:
           • Real estate
           • Vehicles
           • Businesses
           • Investments
           • Personal items
        
        2. Valuation Methods:
           • Market comparison
           • Income approach
           • Cost approach
           • Professional appraisal
        
        3. Required Documents:
           • Title deeds
           • Vehicle logbooks
           • Share certificates
           • Bank statements
           • Business records
        
        4. Professional Input:
           • Registered valuers
           • Financial advisors
           • Property agents
           • Business appraisers
        
        Cost: Valuation fees: 0.1-0.25% of estate value`
      }
    ],
    averageCosts: {
      'Inheritance Process': 'KES 50,000 - 200,000',
      'Will Drafting': 'KES 15,000 - 50,000',
      'Trust Setup': 'KES 50,000 - 200,000',
      'Estate Valuation': '0.1-0.25% of estate value'
    }
  },
  {
    title: 'RETIREMENT',
    icon: <TrendingUpIcon sx={{ fontSize: 40, color: '#795548' }} />,
    color: '#795548',
    content: [
      {
        question: 'How to follow up on your NSSF?',
        answer: `NSSF Tracking Guide:
        1. Online Access:
           • Register on NSSF portal
           • Check contribution history
           • View current balance
           • Download statements
        
        2. Physical Follow-up:
           • Visit NSSF offices
           • Bring ID and NSSF card
           • Request statement
           • Update details
        
        3. Mobile Services:
           • USSD code *236#
           • SMS services
           • Mobile app
           • Payment tracking
        
        4. Documentation:
           • Monthly pay slips
           • Contribution receipts
           • Annual statements
           • Employment records
        
        Cost: Statement request: Free to KES 200`
      },
      {
        question: 'How to plan for retirement using known solutions like insurance?',
        answer: `Insurance Retirement Planning:
        1. Pension Plans:
           • Individual pension plans
           • Income drawdown options
           • Guaranteed returns: 5-7%
           • Tax benefits up to KES 20,000/month
        
        2. Life Insurance with Investment:
           • Unit-linked policies
           • Endowment plans
           • Retirement income plans
           • Medical coverage
        
        3. Investment Options:
           • Balanced funds
           • Money market funds
           • Government securities
           • Real estate investment
        
        4. Risk Management:
           • Portfolio diversification
           • Regular reviews
           • Inflation protection
           • Beneficiary nomination
        
        Cost: Monthly premium: KES 5,000-50,000`
      },
      {
        question: 'How to consolidate your various pensions?',
        answer: `Pension Consolidation Process:
        1. Assessment:
           • List all pension schemes
           • Evaluate benefits
           • Compare charges
           • Check transfer penalties
        
        2. Documentation:
           • Pension statements
           • Transfer forms
           • ID documents
           • Employment records
        
        3. Transfer Process:
           • Contact scheme trustees
           • Fill transfer forms
           • Choose receiving scheme
           • Track transfer
        
        4. Post-Transfer:
           • Confirm receipt
           • Update beneficiaries
           • Investment choices
           • Regular monitoring
        
        Cost: Transfer fees: 0-3% of value`
      },
      {
        question: 'How to withdraw your pension from NSSF and the different insurers?',
        answer: `Pension Withdrawal Guide:
        1. NSSF Withdrawal:
           • Age 50 for early retirement
           • Age 60 for normal retirement
           • Required documents
           • Processing time: 21 days
        
        2. Insurance Pensions:
           • Retirement age options
           • Lump sum vs monthly
           • Tax implications
           • Withdrawal process
        
        3. Documentation Required:
           • ID/Passport
           • Bank details
           • Tax PIN
           • Retirement notice
        
        4. Payment Options:
           • Monthly pension
           • Lump sum payment
           • Partial withdrawal
           • Income drawdown
        
        Cost: Processing fee: KES 200-1,000`
      },
      {
        question: 'Why is retirement planning important? Backed by statistics of how many people retire with a comfortable amount.',
        answer: `Retirement Planning Importance:
        1. Kenya Statistics:
           • Only 15% retire comfortably
           • 55% depend on family
           • 7% have pension plans
           • Average savings: KES 1.2M
        
        2. Financial Security:
           • Medical expenses increase
           • Reduced earning capacity
           • Inflation impact
           • Family responsibilities
        
        3. Quality of Life:
           • Maintain lifestyle
           • Healthcare access
           • Independence
           • Emergency funds
        
        4. Risk Mitigation:
           • Longevity risk
           • Market volatility
           • Healthcare costs
           • Dependency burden
        
        Cost: Financial planning: KES 10,000-50,000`
      },
      {
        question: 'How to finance your exit from your business? How to finance your retirement.',
        answer: `Business Exit & Retirement Financing:
        1. Business Exit Options:
           • Sell to partners/employees
           • Family succession
           • Market sale
           • Gradual transition
        
        2. Valuation Methods:
           • Asset-based valuation
           • Revenue multiplier
           • EBITDA multiple
           • Market comparison
        
        3. Retirement Funding:
           • Business sale proceeds
           • Investment portfolio
           • Rental income
           • Passive income streams
        
        4. Investment Strategy:
           • Diversification
           • Risk management
           • Regular income
           • Capital preservation
        
        Cost: Exit planning: 1-3% of business value`
      }
    ],
    averageCosts: {
      'Monthly Pension Premium': 'KES 5,000 - 50,000',
      'Financial Planning': 'KES 10,000 - 50,000',
      'Business Exit Planning': '1-3% of business value',
      'Pension Transfer': '0-3% of pension value'
    }
  },
];

const WealthManagementGrid = () => {
  const [selectedStep, setSelectedStep] = useState(null);

  const handleStepClick = (index) => {
    setSelectedStep(index);
  };

  const handleClose = () => {
    setSelectedStep(null);
  };

  return (
    <Box sx={{ 
      flexGrow: 1, 
      p: { xs: 1, sm: 2, md: 3 },
      mt: 8,
      background: 'linear-gradient(135deg, #F6F7FF 0%, #FFFFFF 100%)'
    }}>
      <Grid 
        container 
        spacing={{ xs: 2, sm: 2 }} 
        sx={{ 
          p: { xs: 1, sm: 2 },
          width: '100%',
          margin: 0
        }}
      >
        {wealthSteps.map((item, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            lg={3} 
            key={index}
            sx={{ 
              display: 'flex',
              width: '100%'
            }}
          >
            <MotionPaper
              onClick={() => handleStepClick(index)}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 }
              }}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: index * 0.1 }}
              sx={{
                p: 2,
                width: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: { xs: '120px', sm: '140px' },
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: (theme) => theme.shadows[8]
                },
                background: (theme) => `linear-gradient(135deg, ${alpha(item.color, 0.12)} 0%, ${alpha(item.color, 0.05)} 100%)`,
                borderLeft: (theme) => `4px solid ${item.color}`
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {item.icon}
                <Typography 
                  variant="h6" 
                  sx={{ 
                    ml: 1,
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    fontWeight: 600,
                    color: 'text.primary'
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary',
                  fontSize: { xs: '0.875rem', sm: '0.9rem' }
                }}
              >
                {item.description}
              </Typography>
            </MotionPaper>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={selectedStep !== null}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={useMediaQuery(theme => theme.breakpoints.down('sm'))}
        sx={{
          '& .MuiDialog-paper': {
            m: { xs: 0, sm: 2 },
            maxHeight: { xs: '100%', sm: 'calc(100% - 64px)' },
            borderRadius: { xs: 0, sm: 2 }
          }
        }}
      >
        {selectedStep !== null && (
          <>
            <DialogTitle 
              sx={{ 
                px: { xs: 2, sm: 3 },
                py: { xs: 1.5, sm: 2 }
              }}
            >
              <Typography variant="h5" component="h2">
                {wealthSteps[selectedStep].title}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: 'text.secondary' }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent 
              sx={{ 
                px: { xs: 2, sm: 3 },
                py: { xs: 1, sm: 2 },
                overflowY: 'auto'
              }}
            >
              {wealthSteps[selectedStep].title === 'CALCULATORS' ? (
                <FinancialCalculators />
              ) : (
                <Box>
                  {wealthSteps[selectedStep].content.map((item, index) => (
                    <Box key={index} sx={{ mb: 4 }}>
                      <Typography variant="h6" gutterBottom color="primary">
                        {item.question}
                      </Typography>
                      {Array.isArray(item.answer) ? (
                        item.answer.map((line, i) => (
                          <Typography key={i} paragraph>
                            {line}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          component="div"
                          sx={{
                            whiteSpace: 'pre-line',
                            '& p': { mb: 1 }
                          }}
                        >
                          {item.answer}
                        </Typography>
                      )}
                    </Box>
                  ))}
                  {wealthSteps[selectedStep].averageCosts && (
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h6" gutterBottom color="primary">
                        Average Costs
                      </Typography>
                      {Object.entries(wealthSteps[selectedStep].averageCosts).map(([service, cost], index) => (
                        <Typography key={index} paragraph>
                          <strong>{service}:</strong> {cost}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default WealthManagementGrid;
